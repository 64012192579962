import React, { Component } from 'react'
import firstImage from "../images/transport7.jpg"
import secondImage from "../images/transport8.jpg"
import myVideo from "../images/road1.mp4"
import TextLoop from 'react-text-loop'
import Text from 'react-text'
export default class Introduction extends Component {
  styles = {
    video: {
      //position:'fixed',
      zIndex: '-1',
      width: '100%',
      //marginTop: '10px',
      height: '100%',
      
    },
    videoText:{
      textAlign: 'Centre'
      
    },
    layer: {
      backgroundColor: 'rgba(128, 128, 128, 0.9)',
      position: 'fixed',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%'
  }
  }
  render() {
    return (
      
      <div>
        
        <section id="colorlib-hero" className="js-fullheight" data-section="home" >
       <div className='home-text'> 
          <video autoPlay loop muted playsInline style={this.styles.video}>
            <source src={myVideo} type='video/mp4' />
            
          </video>
          <span> Hi! With us you&nbsp;<TextLoop children={["Transport Faster", "Increase Sales", "Worry Less", "Price Perfectly"]} /></span>
        </div>  
        </section>
      
      </div>
    )
  }
}
