import React, { Component } from 'react'

export default class Projects extends Component {

	ColoredLine = ({ color }) => (
		<hr
			style={{
				color: color,
				backgroundColor: color,
				height: 5,
				width: '50%'
			}}
		/>
	);

  render() {
    return (
      <div>
				<section className="colorlib-work" data-section="projects">
					<div className="colorlib-narrow-content">
						<div className="row">
							<div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
								<span className="heading-meta">Wrapping Up</span>
								<h2 className="colorlib-heading animate-box">FAQs</h2>
							</div>
						</div>
						<div className="row">
							<div className="col-md-4 animate-box" data-animate-effect="fadeInLeft">
								<div className="project" style={{backgroundColor:"rgb(0,100,0,0.8)"}}>
								<h2 style={{color:'white',position:'absolute', left:'35%',bottom:'10%'}}>FAQ #1</h2>
									<div className="desc">
										<div className="con">
											<h3><a href="work.html">COLLECTION TIME?</a></h3>
											<h3 style={{ color: 'black' }}>Usually 30 to 75 minutes</h3>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 animate-box" data-animate-effect="fadeInRight" style={{}}>
								<div className="project" style={{backgroundColor:"rgb(0,100,0,0.8)"}}>
								<h2 style={{color:'white',position:'absolute', left:'35%',bottom:'10%'}}>FAQ #2</h2>
									<div className="desc">
										<div className="con">
											<h3><a href="work.html">SAFETY AND PRIVACY?</a></h3>
											<h3 style={{ color: 'black' }}>ONCE LOADED A STICKER WILL BE PLACED AS A SEAL WHICH WILL BE OPENED ON THE DESIRED DESTINATION</h3>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 animate-box" data-animate-effect="fadeInTop">
								<div className="project" style={{backgroundColor:"rgb(0,100,0,0.8)"}}>
								<h2 style={{color:'white',position:'absolute', left:'35%',bottom:'10%'}}>FAQ #3</h2>
									<div className="desc">
										<div className="con">
											<h3><a href="work.html">WHEN TO PAY?</a></h3>
											<h3 style={{ color: 'black' }}>PAY AFTER THE JOB IS DONE</h3>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<this.ColoredLine color="grey"></this.ColoredLine>
      </div>
    )
  }
}
