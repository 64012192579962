
import React, { Component } from 'react'


class Calculator extends Component {
    
  render() {
    return (

        <div>
        <meta charSet="utf-8" />
        <title>Distances btn two cities App</title>
        <section data-section="calculator">
        
               
                
                <div className="jumbotron">
                    <div className="container-fluid">
                        <h1>Free Quote</h1>
                        <p>Please enter the source and destination, and select a transport type as well for an estimated amount</p>
                        <form className="form-horizontal" id="carform">
                            <div className="form-group">
                                <label htmlFor="from" className="col-xs-2 control-label"><i className="far fa-dot-circle" /></label>
                                <div className="col-xs-8">
                                    <input type="text" id="from" placeholder="Origin" className="form-control" onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="to" className="col-xs-2 control-label"><i className="fas fa-map-marker-alt" /></label>
                                <div className="col-xs-8">
                                    <input type="text" id="to" placeholder="Destination" className="form-control" onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="cars" className="col-xs-2 control-label"> <i className="fas fa-car" /></label>
                                <div className="col-xs-4">
                                    <select id="cars" name="cars" form="carform">
                                        <option value="mbc">Motor Bike and Car</option>
                                        <option value="svan">Small Van</option>
                                        <option value="swb2">SWB 2.4m</option>
                                        <option value="lwb">LWB</option>
                                        <option value="xlwb">XLWB 4m</option>
                                        <option value="luton">Luton Van</option>
                                        <option value="lutontail">Luton - Tail Lift</option>
                                    </select>
                                </div>
                            </div>
                        </form>
                        <div className="col-xs-offset-2 col-xs-10">
                            <button className="btn btn-info btn-lg " onClick={window['calcRoute']}><i className="fas fa-map-signs" /></button>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div id="googleMap">
                        </div>
                        <div id="output">
                        </div>
                    </div>
                </div>
                </section>
                
            </div>
            
      
            
               )
            }
          }
          //create autocomplete objects for all inputs

export default Calculator