import React, { Component } from 'react';

import './App.css';
import Sidebar from './components/sidebar';
import WebPage from './components/WebPage';


class App extends Component {
  
  render() {
    return (
      
      
        
          <React.Fragment>
          <Sidebar></Sidebar>
          <WebPage></WebPage>
          </React.Fragment>
      
    );
  }
}

export default App;
