import React, { Component } from 'react'

export default class Timeline extends Component {
  render() {
    return (
      <div>
        <section className="colorlib-experience" data-section="timeline">
          <div className="colorlib-narrow-content">
            <div className="row">
              <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
                <span className="heading-meta">More About Us</span>
                <h2 className="colorlib-heading animate-box">Delivery Details</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="timeline-centered">
                  <article className="timeline-entry animate-box" data-animate-effect="fadeInLeft">
                    <div className="timeline-entry-inner">
                      <div className="timeline-icon color-3">
                        <i className="icon-pen2" />
                      </div>
                      <div className="timeline-label">
                        <h2>MOTORBIKE AND CAR<span> 2021-Present</span></h2>
                        <p>•	Minimun charge 45£ upto 30 miles</p>
                        {/*  <p>•	30-50 miles charges 65£ </p>
                        <p>•	After 50 miles  (1£ per mile) </p>*/}
                        <p>•	Waiting time 12.50£ for 45 mins </p>
                        <p>•	Wait and return (half price on return)</p>
                      </div>
                    </div>
                  </article>
                  <article className="timeline-entry animate-box" data-animate-effect="fadeInTop">
                    <div className="timeline-entry-inner">
                      <div className="timeline-icon color-4">
                        <i className="icon-pen2" />
                      </div>
                      <div className="timeline-label">
                        <h2>SMALL VAN UPTO 1 PALLET OR 400KG<span> 2021-Present</span></h2>
                        <p>•	Minimun charge 50£ upto 30 miles </p>
                        {/*<p>•	30-50 miles charges 65£ </p>
                        <p>•	After 50 miles  (1£ per mile) </p>*/}
                        <p>•	Waiting time  12.50£ for 45 mins </p>
                        <p>•	Wait and return (half price on return)</p>
                      </div>
                    </div>
                  </article>
                  <article className="timeline-entry animate-box" data-animate-effect="fadeInLeft">
                    <div className="timeline-entry-inner">
                      <div className="timeline-icon color-5">
                        <i className="icon-pen2" />
                      </div>
                      <div className="timeline-label">
                        <h2>SWB 2.4M UPTO 2 PALLETS OR 800KG<span> 2021-Present</span></h2>
                        <p>•	Minimun charge 65£ upto 40 miles</p> 
                        {/*<p>•	After 30 miles charges (1.20£ per mile)</p> */}
                        <p>•	Waiting time 12.50£ for 45 mins</p> 
                        <p>•	Wait and return (half price on return)</p> 
                      </div>
                    </div>
                  </article>
                  <article className="timeline-entry begin animate-box" data-animate-effect="fadeInBottom">
                    <div className="timeline-entry-inner">
                      <div className="timeline-icon color-1">
                        <i className="icon-pen2" />
                      </div>
                      <div className="timeline-label">
                        <h2>LWB UPTO 3 PALLETS OR 1000KG<span> 2021-Present</span></h2>
                        <p>•	Minimun charge 65£ upto 35 miles</p> 
                        {/*<p>•	After 35 miles charges (1.35£ per mile)</p> */}
                        <p>•	Waiting time free for 30 mins after that 15.00£ for 45 mins</p> 
                        <p>•	Wait and return (half price on return)</p> 
                      </div>
                    </div>
                  </article>
                  <article className="timeline-entry begin animate-box" data-animate-effect="fadeInBottom">
                    <div className="timeline-entry-inner">
                      <div className="timeline-icon color-2">
                        <i className="icon-pen2" />
                      </div>
                      <div className="timeline-label">
                        <h2>XLWB UPTO 4M UPTO 4 PALLETS OR 1150KG<span> 2021-Present</span></h2>
                        <p>•	Minimun charge 70£ upto 35 miles</p> 
                        {/*<p>•	After 35 miles charges (1.50£ per mile)</p> */}
                        <p>•	Waiting time free for 30 mins after that 17.50£ for 45 mins</p> 
                        <p>•	Wait and return (half price on return)</p> 
                      </div>
                    </div>
                  </article>
                  <article className="timeline-entry begin animate-box" data-animate-effect="fadeInBottom">
                    <div className="timeline-entry-inner">
                      <div className="timeline-icon color-3">
                        <i className="icon-pen2" />
                      </div>
                      <div className="timeline-label">
                        <h2>LUTON VAN UPTO 5-6 PALLETS OR 1150KG<span> 2021-Present</span></h2>
                        <p>•	Minimun charge 75£ upto 35 miles</p> 
                        {/*<p>•	After 35 miles charges (1.700£ per mile)</p> */}
                        <p>•	Waiting time free for 30 mins after that 20.00£ for 45 mins</p> 
                        <p>•	Wait and return (half price on return)</p> 
                      </div>
                    </div>
                  </article>
                  <article className="timeline-entry begin animate-box" data-animate-effect="fadeInBottom">
                    <div className="timeline-entry-inner">
                      <div className="timeline-icon color-4">
                        <i className="icon-pen2" />
                      </div>
                      <div className="timeline-label">
                        <h2>LUTON VAN WITH TAIL LIFT UPTO 5-PALLETS OR 1000KG<span> 2021-Present</span></h2>
                        <p>•	Minimun charge 85£ upto 30 miles</p> 
                        {/*<p>•	After 30 miles charges (1.90£ per mile)</p> */}
                        <p>•	Waiting time free for 30 mins after that 20.00 £ for 45 mins</p> 
                        <p>•	Wait and return (half price on return)</p> 
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}
