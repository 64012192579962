import React, { Component } from 'react'
import firstImage from "../images/SN.png"


export default class Sidebar extends Component {
  listStyle = {
    marginBottom: '15px'
  }
  render() {
    return (
      <div>
        <div>
          <nav href="#navbar" className="js-colorlib-nav-toggle colorlib-nav-toggle" data-toggle="collapse" data-target="#navbar" aria-controls="navbar"><i /></nav>
          <aside id="colorlib-aside" className="border js-fullheight" style={{ backgroundColor: "rgb(18, 93, 87,0.8)" }}>
            <div className="text-center">
              <a href="/"><div className="author-img" style={{ backgroundImage: `url(${firstImage})` }} /></a>
              <h1 id="colorlib-logo"><a href="/" style={{ color: "rgb(255, 255, 255, 0.8)", fontFamily: 'impact', position:"center"}}>SN Transports</a></h1>
            </div>
            <nav id="colorlib-main-menu" role="navigation" className="navbar">
            <ul>
              <div id="navbar" className="collapse">

                  <li className="active" style={this.listStyle}><a href="#introduction" data-nav-section="home" style={{ color: 'white' }}>Home</a></li>
                  <li style={this.listStyle}><a href="#about" data-nav-section="about" style={{ color: 'white' }}>About</a></li>
                  <li style={this.listStyle}><a href="#timeline" data-nav-section="timeline" style={{ color: 'white' }}>Services Details</a></li>
                  <li style={this.listStyle}><a href="#projects" data-nav-section="projects" style={{ color: 'white' }}>FAQs</a></li>
                  <li><a href="#calculator" data-nav-section="calculator" style={{ color: 'white' }}>Distance</a></li>
              </div>
              </ul>
            </nav>
            {/* 
            <nav id="colorlib-main-menu">
              <ul>
                <li style={this.listStyle}><a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer"><i className="icon-facebook2" /></a></li>
                <li style={this.listStyle}><a href="https://twitter.com/" target="_blank" rel="noopener noreferrer"><i className="icon-twitter2" /></a></li>
                <li style={this.listStyle}><a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer"><i className="icon-instagram" /></a></li>
                <li style={this.listStyle}><a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer"><i className="icon-linkedin2" /></a></li>
                <br></br>
                <li style={this.listStyle} className="email"><a href="mailto:OPERATIONS@SNTRANSPORT.CO.UK" style={{ color: 'white', position: "center" }}><i className="fas fa-envelope" style={{ color: 'white', fontSize: "150%"  }}></i> </a></li>
                <li style={this.listStyle} className="phone"><a href="tel:+447453323574" style={{ color: 'white', position: "center"}}><i className="fas fa-phone" style={{ color: 'white', fontSize: "150%" }}></i> </a></li>
                <li style={this.listStyle} className="phone"><a href="tel:+447449120057" style={{ color: 'white', position: "center" }}><i className="fas fa-phone" style={{ color: 'white', fontSize: "150%"  }}></i> </a></li>
              </ul>
              
            </nav>
            */}
          </aside>
        </div>
      </div>
    )
  }
}
