import React, { Component } from 'react'

export default class About extends Component {
  render() {
    return (
      <div>
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
        <section className="colorlib-about" data-section="about">
        <div className="colorlib-narrow-content">
            <div className="row">
            <div className="col-md-12">
                <div className="row row-bottom-padded-sm animate-box" data-animate-effect="fadeInLeft">
                <div className="col-md-12">
                    <div className="about-desc" >
                    <span className="heading-meta">About Us</span>
                    <h2 className="colorlib-heading">Who We Are</h2>
                    <p style={{ color: 'black', textAlign: 'justify' }}>SN Transports Limited aim to excel in planning the route and providing a top class service to our customers. Constant communication and providing meaningful updates to the customer. We will be proactive and will take full responsibility for the job. We are available 24/7 and 365 days a year. Book easily via email or whatsapp.</p>
                    <h2 className="colorlib-heading">What We Do</h2>
                    <p style={{ color: 'black', textAlign: 'justify' }}> Transit insurance of £20k with identity badges including photo for every driver and real time tracking available as well. We provide status of your booking when the driver is on its way, on site to pick up, when loaded with an eta and when delivered. We will work together with the customer to find a perfect solution to any possible problem. SN transports limited care about all customers and aims to provide transport system which is safe, reliable, efficient and satisfying to both user and operators. We are experts in what we do and are client-focused to provide a mean of transport quickly and within budget. With over 12 fleets on the road every single day and more than 100 vehicles as a subcontractor available 24/7 and 365 days a year. We will only use subcontractors when our fleets are full or out of the area. No need to worry as SN transports limited use trusted subcontractors on whom we rely every single day. SN transports limited treat every job with the same priority and aim to provide a satisafcatory service to our customers. Ranging from passports to machinery equipment, we will give our all in to satsfy our customers and ensure that the goods are in the right place and within the given time. If and when there is an uncontrollable delay, we will  be in a constant communication with our customer.  </p>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </section>
        <section className="colorlib-about">
        <div className="colorlib-narrow-content">
            <div className="row">
            <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
                <span className="heading-meta">Our Offers</span>
                <h2 className="colorlib-heading">Highlights</h2>
            </div>
            </div>
            <div className="row row-pt-md">
            <div className="col-md-4 text-center animate-box">
                <div className="services color-1">
                <span className="icon">
                    <i className="fa fa-refresh" />
                </span>
                <div className="desc">
                    <h3>DELIVERIES</h3>
                    <p>Same Day, Urgent Same Day, Next Day, Multi-Drops, 3-5 Days, Wait and Return</p>
                </div>
                </div>
            </div>
            <div className="col-md-4 text-center animate-box">
                <div className="services color-3">
                <span className="icon">
                    <i className="icon-phone3" />
                </span>
                <div className="desc">
                    <h3>SERVICES</h3>
                    <p>Documents, Pallet delivery, Furniture, Fragile items, Airport cargo, Art and antique, Medical items, Hospitality and events, Construction, Textile</p>
                </div>
                </div>
            </div>
            <div className="col-md-4 text-center animate-box">
                <div className="services color-5">
                <span className="icon">
                    <i className="fa fa-truck" />
                </span>
                <div className="desc">
                    <h3>FLEETS</h3>
                    <p>Motorbike, Car, Van, SWB, MWB & LWB upto 2.4m, 3m & 4m respectively, Luton Van (Tail Lift)</p>
                </div>
                </div>
            </div>
            {/*
            <div className="col-md-4 text-center animate-box">
                <div className="services color-2">
                <span className="icon">
                    <i className="icon-data" />
                </span>
                <div className="desc">
                    <h3>Dev Ops</h3>
                    <p>Jenkins , Kubernetes , Docker </p>
                </div>
                </div>
            </div>
            <div className="col-md-4 text-center animate-box">
                <div className="services color-4">
                <span className="icon">
                    <i className="icon-layers2" />
                </span>
                <div className="desc">
                    <h3>Graphic Design</h3>
                    <p>My friend knows .. P</p>
                </div>
                </div>
            </div>
            <div className="col-md-4 text-center animate-box">
                <div className="services color-6">
                <span className="icon">
                    <i className="icon-phone3" />
                </span>
                <div className="desc">
                    <h3>Digital Marketing</h3>
                    <p>I use Instagram eight hours a day :) </p>
                </div>
                </div>
            </div>
            */}
            </div>
        </div>
        </section>
      </div>
    )
  }
}
