import React, { Component } from 'react';

import Introduction from './introduction'
import About from './about'
import Timeline from './timeline'
import Projects from './projects'
import Calculator from './Calculator'
import { Container, Button, Link } from 'react-floating-action-button'
class WebPage extends Component {

  render() {
    return (
     
          
          <div id="colorlib-main">
            
            <Introduction></Introduction>
            <About></About>
            <Timeline></Timeline>
            <Projects></Projects>
            <Calculator></Calculator>
            <Container>
            <Link href="mailto:OPERATIONS@SNTRANSPORT.CO.UK"
                tooltip="Email"
                icon="fas fa-envelope" />
            <Link href="tel:+447449120057"
                tooltip="Contact #1"
                icon="fa fa-phone" />
            <Link href="tel:+447453323574"
                tooltip="Contact #2"
                icon="fa fa-phone" />
                
            <Button
                tooltip="Contact Us"
                icon="fas fa-phone"
                rotate={true}/>
        </Container>
          </div>
        
      
    );
  }
}

export default WebPage;
